import { Box, Button, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { gt, isNil, length, not, path, prop } from 'ramda'
import { resetState } from 'pages/onboarding/components/onboardingSlice'
import { updateUserData } from 'store/userSlice'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  setIsSpotlightOpen,
  setTotalSteps,
  setType
} from 'components/spotlight/spotlightSlice'
import { useAnalytics } from 'hooks'
import AccessibleModal from 'components/modals/AccessibleModal'

const ConfirmationModal = () => {
  const dispatch = useDispatch()
  const letters = useSelector(path(['school', 'letters']))
  const { referralLinks } = useSelector(prop('sidebar'))

  const hasReferralLinks = gt(length(referralLinks), 0)
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const justOnboarded = not(isNil(search.get('justOnboarded')))
  const { sendEvent } = useAnalytics()

  const handleClose = () => {
    dispatch(resetState())
    dispatch(updateUserData({ name: 'has_been_shown_a_modal', value: true }))
    navigate('/home', { replace: true })
  }

  const handleTakeTour = () => {
    dispatch(resetState())
    dispatch(updateUserData({ name: 'has_been_shown_a_modal', value: true }))
    navigate('/home', { replace: true })
    dispatch(setIsSpotlightOpen(true))
    dispatch(setType('introduction'))
    dispatch(setTotalSteps(hasReferralLinks ? 7 : 6))
    // Analytics
    sendEvent('Spotlight_tour', {
      Event: 'Start',
      Step: 0
    })
  }

  return (
    <AccessibleModal
      onClose={handleClose}
      open={justOnboarded}
      title={
        <Stack alignItems="center" spacing={2}>
          <Box
            sx={{
              backgroundImage: 'url(/vectors/you-logo.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 24,
              width: 24
            }}
          />
          <Typography
            sx={{
              textTransform: 'none'
            }}
            textAlign="center"
            variant="h3"
          >
            Way to put YOU first!
          </Typography>
        </Stack>
      }
    >
      <Stack
        alignItems="center"
        spacing={2}
        sx={{ maxWidth: 386, textAlign: 'center', width: 1 }}
      >
        <Typography component="span" variant="body2">
          Hi there! Let us show you around — it’ll just take a second.
        </Typography>
        <Typography component="span" variant="body2">
          With YOU at {letters}, you can find and save customized resources for
          every aspect of student life: class, career prep, socializing...you
          name it!
        </Typography>
        <Stack alignItems="center" spacing={4}>
          <Box
            component="img"
            height={163}
            mx="auto"
            src="/vectors/onboard-modal.svg"
            width={220}
          />

          <Button onClick={handleTakeTour}>Show me around</Button>
          <Button onClick={handleClose} variant="text">
            I&apos;ll Explore On My Own
          </Button>
        </Stack>
      </Stack>
    </AccessibleModal>
  )
}

export default ConfirmationModal
