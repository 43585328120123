import { prop, equals, or, isNil, not, and, includes } from 'ramda'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useGetPanicCardQuery } from 'api/schoolApi'
import parse from 'html-react-parser'
import { Button, Stack, Typography } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GA from 'services/gaService'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import AccessibleModal from './AccessibleModal'
import Icon from 'components/icon/Icon'

const CrisisModal = ({ isOpen, setIsCrisisModalOpen }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const {
    domain: schoolDomain,
    help,
    fhn_subdomain,
    fhn_vanity_url,
    triage_enabled
  } = useSelector(prop('school'))
  const isDev = equals(process.env.REACT_APP_PUBLIC_APP_ENV, 'development')
  const domain = isDev ? process.env.REACT_APP_PUBLIC_ORG_DOMAIN : schoolDomain

  // Crisis Modal on hash route
  useEffect(() => {
    if (
      and(
        not(isNil(window.location.hash)),
        includes('#crisis', window.location.hash)
      )
    ) {
      setIsCrisisModalOpen(true)
    }
  }, [setIsCrisisModalOpen])

  const { data } = useGetPanicCardQuery(domain, {
    skip: or(isNil(schoolDomain), isNil(domain))
  })

  // this was taken from the previous code base. it sets up the FHN link
  const rootDomain = window.location.hostname
    .split('.')
    .reverse()
    .splice(0, 2)
    .reverse()
    .join('.')

  const fhnUrl = fhn_vanity_url
    ? fhn_vanity_url
    : ('https://' + help ?? fhn_subdomain + '.' + rootDomain)

  return (
    <AccessibleModal
      onClose={() => {
        setIsCrisisModalOpen(false)
        if (not(isNil(window.location.hash))) navigate(pathname)
      }}
      open={isOpen}
      title={
        <Stack alignItems="center" spacing={2}>
          <Icon>support</Icon>
          <Typography variant="overline">
            <FormattedMessage defaultMessage="help in crisis" id="ErrFug" />
          </Typography>
        </Stack>
      }
    >
      <Stack alignItems="center" spacing={2}>
        <Typography textAlign="center" variant="h3">
          {prop('title', data)}
        </Typography>
        {/*we link to find help now if they have paid for it otherwise use the card content*/}
        {triage_enabled ? (
          <>
            <Typography component="span" textAlign="center" variant="body2">
              <FormattedMessage
                defaultMessage="Answer four anonymous questions to find the most appropriate support available to you."
                id="aXC813"
              />
            </Typography>
            <Button
              LinkComponent={Link}
              onClick={() => GA.click('Crisis-Header-Modal-CTA', 'Click')}
              target="_blank"
              to={fhnUrl}
            >
              <FormattedMessage
                defaultMessage="Start your anonymous search"
                id="UJQJYh"
              />
            </Button>
            <Typography component="span" fontWeight={600} variant="body2">
              <FormattedMessage
                defaultMessage="For serious or life-threatening situations:"
                id="0/OtSo"
              />
            </Typography>
            <Typography
              component="span"
              sx={{
                textAlign: 'center'
              }}
              variant="body2"
            >
              <FormattedMessage
                defaultMessage="Call {911} for medical emergencies or {988} for suicide and crisis support."
                id="6fZ8Ax"
                values={{
                  911: <Link to="tel:911">911</Link>,
                  988: <Link to="tel:988">988</Link>
                }}
              />
            </Typography>
          </>
        ) : (
          not(isNil(data)) && (
            <Stack
              sx={{
                alignItems: 'center'
              }}
            >
              {parse(prop('page_content', data))}
              {not(isNil(prop('cta_url', data))) && (
                <Button
                  LinkComponent={Link}
                  onClick={() => GA.click('Crisis-Header-Modal-CTA', 'Click')}
                  target="_blank"
                  to={prop('cta_url', data)}
                >
                  {prop('cta', data)}
                </Button>
              )}
            </Stack>
          )
        )}
      </Stack>
    </AccessibleModal>
  )
}

CrisisModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsCrisisModalOpen: PropTypes.func.isRequired
}

export default CrisisModal
